import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Crypto App Template | Crypto App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/crypto-nft-mobile-app/"
    metaDescription="Whether you want to build an NFT art marketplace or a crypto trading platform, this template has all the required building blocks you need."
    description="
    h2:Say hello to our crypto app design template for mobile 
    <br/>
    Believers in the blockchain, web3 innovators, cryptocurrency influencers, NFT traders — this template was made just for you. Our crypto app template for mobile is easy to use and is certain to streamline the <a:https://uizard.io/prototyping/>UI prototyping</a> process for your own crypto project.
    <br/>
    h3:Crypto mobile app design made easy
    <br/>
    It's hard enough to build a truly decentralized web architecture on the blockchain. That's why designing a compelling mobile app for your service should be easy. As with all Uizard mobile templates, our crypto <a:https://uizard.io/templates/mobile-app-templates/>mobile app design template</a> has all your UI considerations baked in, meaning you can focus purely on personalization.
    <br/>
    h3:The lego blocks for your crypto-based service
    <br/>
    Whether you want to build an NFT art marketplace or a crypto trading platform, you'll need some screens to provide users with an easy way to manage their portfolio, trade assets, and mint, buy, sell, bid, and auction their NFT art. This template has all the required building blocks to enable you to build a unique user experience, using battle-tested design principles. Sign up to Uizard Pro and start your design project today.
    "
    pages={[
      "The classic screens for signing up and logging in to the app",
      "A user profile screen with quick access to their account settings, payment methods, and security settings",
      "A home screen with an overview of your portfolio activities showcasing crypto assets, top NFTs, and buttons for quickly buying / selling and sending / receiving assets",
      "A crypto screen showing the latest movement of the coin and buttons to buy and sell cryptocurrencies",
      "A wallet screen",
      "A screen for converting cryptocurrencies",
      "A dedicated portfolio screen with a button to swap between cryptocurrencies and NFTs",
      "An NFT marketplace screen",
    ]}
    projectCode="mKeRQpM0AVf88wWKo0vx"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Crypto NFT Mobile App: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Crypto NFT Mobile App: wallet screen"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Crypto NFT Mobile App: crypto conversion screen"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Crypto NFT Mobile App: user profile screen"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of Crypto NFT Mobile App template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/crypto-nft-mobile-app/Crypto-App-Cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/crypto-nft-mobile-app/Crypto-App-Wallet.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/crypto-nft-mobile-app/Crypto-App-Convert.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/crypto-nft-mobile-app/Crypto-App-Profile.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/crypto-nft-mobile-app/Crypto-App-Summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
